/**
 * renders profile page
 *
 * props: editUser(), user
 *
 *
 * states: none
 *
 * RoutesLIst -> Profile
 */

function Profile({ editUser, user }) {

  return (
    <div>

    </div>
  )
}

export default Profile;